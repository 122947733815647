$lightBlue: #3c6897;

* {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  line-height: 1.25;
}
.mobiledropdown {
  display: none;
  align-items: center;
  gap: 10px;
  color: white;
  margin-right: 50px;
  height: 50px;
  width: 100%;
  justify-content: space-evenly;
  :hover {
    background-color: rgb(78, 111, 148);
  }
  h6 {
    font-size: 15px;
    font-weight: 200;
  }
}
.mobilelinks {
  display: none;
}

.mobilelogin {
  background-color: rgb(235, 232, 232);
  color: black;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  border: 1px solid white;
  margin-right: 1vh;
  text-decoration: none;
}
/* Test mobile styling */
@media screen and (max-width: 1023px) {
  .inputContact {
    margin-right: 0px !important;
    margin-left: 3px !important;
  }
  #winnerposts {
    width: auto !important;
  }
  .mobilenavcontainer {
    display: flex !important;
  }
  .applicationnavcontainer {
    display: none !important;
  }
  #applyinfobutton {
    display: none !important;
  }
  .applyimg {
    max-width: 100px !important;
  }
  .adminprocess {
    display: block !important;
  }
  .links {
    display: none !important;
  }
  #valuesSummary {
    display: none !important;
  }
  .mobiledropdown {
    display: flex !important;
  }
  .applicationNavButton {
    margin: 0 !important;
    max-width: 95px;
  }
  .table_container {
    max-width: 95vw !important;
  }
  #content {
    max-width: 48vw !important;
    margin-left: 2px !important;
  }
  #content2 {
    max-width: 48vw !important;
    margin-left: 2px !important;
  }
  .add {
    margin-left: 8px !important;
    display: block !important;
  }
  #PrevGrantmenu {
    flex-direction: column !important;
  }

  .inputPrevGrant {
    max-width: 35vw !important;
  }
  .applicationbutton {
    padding: 10px 10px !important;
    max-width: 300px;
  }
  .inputDetails {
    flex-direction: column !important;
  }
  .img {
    display: none !important;
  }
  .mobilelinks {
    display: flex !important;
  }
  .container {
    padding: 0 !important;
  }
  footer {
    display: none !important;
  }
  .post {
    display: block !important;
    margin-left: 5vh !important;
    margin-right: 5vh !important;
  }
  img {
    width: 300px;
  }
  .containerTextBorder {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .posts {
    width: auto !important;
  }
}

.app {
  justify-content: center;
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;

    /*
general css for all pages
    */
    button a {
      text-decoration: none;
      color: white;
    }
    a {
      text-decoration: underline;
      color: black;
    }
    .containerTextBorder {
      display: block;
      color: #000;
      border-color: #706f6f;
      border-style: solid;
      border-width: 1px;
      padding: 18px;
      align-items: center;
      margin-left: 30vh;
      margin-right: 30vh;
      justify-content: center;
    }

    h3 {
      margin-bottom: 4px;
    }
    /* 
=================
Table
=====================
*/
    input[type="checkbox"] {
      /* Double-sized Checkboxes */
      -ms-transform: scale(1.5); /* IE */
      -moz-transform: scale(1.5); /* FF */
      -webkit-transform: scale(1.5); /* Safari and Chrome */
      -o-transform: scale(1.5); /* Opera */
      transform: scale(1.5);
      padding: 10px;
    }

    .table_container {
      max-width: 75vw;
      /* max-height: 500px; */
      min-width: 50vw;
      margin: 0 auto;
      overflow: auto;
      margin-bottom: 100px;

      button {
        padding: 10px;
        border: none;
        background-color: teal;
        cursor: pointer;
        color: white;
      }
    }

    .table {
      width: 100%;
      border-spacing: 0;
      overflow: hidden;
    }

    caption {
      font-style: italic;
      font-size: 90%;
      margin-bottom: 10px;
    }

    .table th {
      background: #fff;
      padding: 8px 10px;
      border-bottom: 1px solid #1a1a1a;
      font-weight: bold;
      text-align: left;
    }

    .table th.up {
      background-image: url("./img/up_arrow.png");
    }
    .table th.down {
      background-image: url("./img/down_arrow.png");
    }
    .table th.default {
      background-image: url("./img/default.png");
    }
    th.up,
    th.default,
    th.down {
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center right;
    }

    .table td {
      border-top: 1px solid #ddd;
      padding: 8px 20px;
    }
    .table select {
      border-top: 1px solid #ddd;
      padding: 9px 20px;
    }
    td:hover {
      cursor: pointer;
    }
    .table tbody tr:first-child td {
      border-top: none;
    }
    .table tbody tr:first-child select {
      border-top: none;
    }
    .table tbody tr:nth-child(n) select {
      background: #eff0f0;
    }
    .table tbody tr:nth-child(n) td {
      background: #eff0f0;
    }
    .table tbody tr:nth-child(2n) td {
      background: #fff;
    }
    .table tbody tr:nth-child(2n) select {
      background: #fff;
    }
    .read-more-button {
      width: max-content;
      padding: 5px 10px;
      border: none;
      cursor: pointer;
      background-color: white;
      border: 1px solid teal;
      color: teal;
      &:hover {
        border: 1px solid white;
        background-color: $lightBlue;
        color: black;
      }
    }
    .link {
      text-decoration: none;
      color: inherit;
      font-size: 20px;
    }
    td {
      border: 1px solid black;
      margin: 0px 0px;
      padding: 5px 5px;
    }
    select {
      margin: 0px 0px;
      padding: 5px 5px;
    }

    //LOGIN & REGISTER

    .Apply {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      background-color: white;
      margin-top: 10vh;

      h1 {
        font-size: 20px;
        color: $lightBlue;
        margin-bottom: 20px;
      }

      form {
        display: flex;
        flex-direction: column;
        padding: 50px;
        background-color: white;
        width: 200px;
        gap: 20px;

        input {
          padding: 10px;
          border: none;
          border-bottom: 1px solid gray;
        }

        button {
          padding: 10px;
          border: none;
          background-color: teal;
          cursor: pointer;
          color: white;
        }

        p {
          font-size: 12px;
          color: red;
          text-align: center;
        }

        span {
          color: white;
          font-size: 14px;
          text-align: center;
        }
      }
    }

    .auth {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 5vh;
      width: 100%;
      background-color: white;
      font-size: 14px;

      h1 {
        font-size: 20px;
        color: white;
        margin-bottom: 5px;
      }

      form {
        text-align: center;
        display: flex;
        flex-direction: column;
        padding: 50px;
        background-color: rgb(78, 111, 148);
        width: 250px;
        gap: 20px;
        font-size: 20px;
        color: white;
        border-radius: 25px;
        align-items: center;

        input {
          padding: 10px;
          border: none;
          border-bottom: 1px solid gray;
          font-size: 14px;
          border-color: #9d9a9a;
        }
        input:invalid {
          border: 1px dashed 333;
        }
        button {
          padding: 10px;
          border: solid 2px white;
          background-color: grey;
          cursor: pointer;
          color: white;
          width: 70%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-weight: bold;
          border-radius: 10px;
        }

        p {
          font-size: 12px;
          color: white;
          text-align: center;
          border-style: solid;
        }

        span {
          font-size: 16px;
          text-align: center;
        }
      }
    }

    //NAVBAR
    .navbar {
      .container {
        padding: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $lightBlue;
        flex-direction: row;
        .logo {
          img {
            height: 100%;
            margin-right: 1vh;
          }
        }
        .links {
          display: flex;
          align-items: center;
          gap: 30px;
          color: white;
          margin-right: 50px;
          :hover {
            background-color: rgb(78, 111, 148);
            text-decoration: none;
          }
          h6 {
            font-size: 18px;
            font-weight: bold;
          }
          .mobilelinks {
            display: none;
          }

          span {
            cursor: pointer;
          }

          .write {
            background-color: grey;
            color: white;
            width: 85px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            border: 2px solid white;
            margin-right: 1vh;
            padding: 4px;
            font-size: 18px;
            text-decoration: none;

            &:hover {
              color: teal;
              background-color: white;
              border: 1px solid teal;
            }
          }
        }
      }
    }

    //FOOTER

    footer {
      color: #fff;
      margin-top: 100px;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: $lightBlue;
      display: flex;
      align-items: center;
      //justify-content: space-evenly;
      font-size: 16px;
      position: fixed;
      bottom: 0;
      width: 100%;
      img {
        height: 50px;
        margin-left: 2rem;
      }
      span {
        margin-right: 2rem;
      }
      .footerText {
        color: white;
        font-size: 14;
        display: flex;
        align-items: center;
        gap: 10px;
        color: white;
        margin-right: 50px;
      }
      .footerText:hover {
        background-color: rgb(78, 111, 148);
      }
    }
    .dropdown {
      position: relative;
      display: inline-block;
      display: flex;
      justify-content: center;
      margin: 2vh;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      padding: 12px 16px;
      z-index: 1;
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }
    .select {
      width: 100px;
      height: 50px;
      text-align: center;
      font-size: 20px;
    }

    .footerText {
      color: white;
      font-size: 18;
    }
    //HOME

    .posts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;

      text-align: left;
    }
    .home {
      background-color: white;
      .posts {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 150px;
        text-align: left;

        .post {
          display: flex;
          gap: 100px;
          margin-left: 10vh;
          margin-right: 10vh;
          text-align: left;

          &:nth-child(2n + 1) {
            flex-direction: row-reverse;
          }

          .img {
            //flex: 2;
            position: relative;

            &::after {
              content: "";
              width: 100%;
              height: 100%;
              background-color: white;
              position: absolute;
              top: 20px;
              left: -20px;
              z-index: -1;
            }

            img {
              width: 100%;
              max-height: 400px;
              object-fit: cover;
            }
          }

          .content {
            flex: 3;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h1 {
              font-size: 48px;
            }

            p {
              font-size: 18px;
            }

            button {
              width: max-content;
              padding: 10px 20px;
              border: none;
              cursor: pointer;
              background-color: white;
              border: 1px solid teal;
              color: teal;

              &:hover {
                border: 1px solid white;
                background-color: $lightBlue;
                color: black;
              }
            }
          }
        }
      }
    }

    //SINGLE

    .single {
      display: flex;
      gap: 50px;
      margin-bottom: 75px;

      .content {
        flex: 5;
        display: flex;
        flex-direction: column;
        gap: 30px;

        img {
          width: 100%;
          height: 300px;
          object-fit: cover;
        }
        button {
          padding: 10px;
          border: none;
          background-color: teal;
          cursor: pointer;
          color: white;
        }

        .user {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 14px;
          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
          }

          span {
            font-weight: bold;
          }
        }

        .edit {
          display: flex;
          gap: 5px;

          img {
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }

        h1 {
          font-size: 42px;
          color: #333;
        }
        h3 {
          font-size: 30px;
        }

        p {
          font-size: 20px;
          line-height: 30px;
        }
      }
      .menu {
        flex: 3;
        display: flex;
        flex-direction: column;
        gap: 25px;

        h1 {
          font-size: 20px;
          color: #555;
        }

        .post {
          display: flex;
          flex-direction: column;
          gap: 10px;

          img {
            width: 100%;
            height: 200px;
            object-fit: cover;
          }

          h2 {
            color: #555;
          }

          button {
            width: max-content;
            padding: 7.5px 15px;
            border: none;
            cursor: pointer;
            color: teal;
            background-color: white;
            border: 1px solid teal;

            &:hover {
              border: 1px solid white;
              background-color: $lightBlue;
              color: black;
            }
          }
        }
      }
    }

    //CONTACTDETAILS

    .applicationbutton {
      cursor: pointer;
      color: white;
      background-color: teal;
      border: 1px solid teal;
      border-radius: 6px;
      padding: 7px 9px;
      font-size: 15px;
      font-weight: bold;
      display: flex;
      margin-left: 10px;
      margin-right: 10px;
    }
    .applicationNavButton {
      cursor: pointer;
      color: white;
      background-color: teal;
      border: 1px solid teal;
      border-radius: 6px;
      padding: 7px 9px;
      font-size: 15px;
      display: flex;
      font-weight: bold;
      text-decoration: none;
    }
    .label {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
    }
    .prevUploadText {
      margin-top: 5px;
      margin-bottom: 10px;
    }
    .add {
      margin-top: 20px;
      display: flex;
      gap: 20px;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 75px;

      //prevgrant
      #PrevGrantadd {
        flex-direction: row;
      }

      #PrevGrantmenu {
        flex-direction: row;
      }
      .prevGrantText {
        margin-top: 10px;
        font-size: 14px;
        flex-direction: row;
      }

      h1 {
        font-size: 37px;
        margin-bottom: 8px;
      }
      .inputContainer {
        display: flex;
      }
      .content {
        flex: 5;
        display: flex;
        flex-direction: column;
        gap: 20px;

        input {
          padding: 10px;
          border: 1px solid lightgray;
        }

        .editorContainer {
          height: 200px;
          border: 1px solid lightgray;
          overflow: hidden;

          textarea {
            width: 99.5%;
            height: 100%;
            resize: none;
            overflow: auto;
            font-size: 15px;
            border-style: none;
            margin-bottom: 20px;
            padding-left: 6px;
          }
          .editor {
            height: 100%;
            border: none;
          }
        }
      }

      .menu {
        flex: 3;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .label {
          font-size: 16px;
        }
        .item {
          border: 1px solid lightgray;
          padding: 10px;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          font-size: 14px;
          color: #555;

          h1 {
            font-size: 20px;
            margin-bottom: 10px;
          }

          .spanProjectDetails {
            margin-bottom: 10px;
          }

          .file {
            text-decoration: underline;
            cursor: pointer;
            margin-top: 10px;
          }

          .buttons {
            display: flex;
            justify-content: space-between;
            text-decoration: none;

            // :first-child {
            //   cursor: pointer;
            //   color: teal;
            //   background-color: teal;
            //   border: 1px solid teal;
            //   padding: 3px 5px;
            // }
            // :last-child {
            //   cursor: pointer;
            //   color: white;
            //   background-color: teal;
            //   border: 1px solid teal;
            //   padding: 3px 5px;
            // }
          }

          .cat {
            display: flex;
            align-items: center;
            gap: 2px;
            color: teal;
          }
        }
      }
    }
  }
}
//success startar här

#valuesSummary {
  color: #000;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  line-height: 1.25;

  p {
    font-size: 18px;
    margin-bottom: 5px;
    font-style: italic;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  }
}

.valueDetails {
  color: #000;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  line-height: 1.25;
  gap: 10px;
}

//Submitted börjar här

.listSubmitted {
  font-size: 16px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  margin-top: 2px;
  margin-bottom: 2px;
}

#textSubmitted {
  font-size: 18px;
  margin-top: 2px;
  margin-bottom: 2px;
}
